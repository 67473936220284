<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span>{{ l('分析') }}</span>
				</div>
			</div>
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('考勤周期')"> </q-label>
				</a-col>
				<a-col :span="8">
					<a-month-picker :allowClear="false" v-model="attDate" @change="handChang" />
				</a-col>
				<a-col :span="10">
					<p style="font-size: 10px;color:#BEBEBE ">{{ startTime }}~{{ endTime }}</p>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('人员')"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="CheckInUser" @click="selectUser">
						<a-tag color="#2db7f5" v-for="item in UserList" :key="item.id" style="cursor: pointer;">
							{{ item.realName }}
						</a-tag>
					</div>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('公司')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-tree-select
						class="left"
						allowClear
						@change="handleCompanyChange"
						:placeholder="l('Company')"
						style="width:200px;"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="input.companyId"
					/>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('部门')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-tree-select
						v-model="input.departmentId"
						allowClear
						style="width:200px;"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						@select="SelectDepartmentTree"
					>
					</a-tree-select>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('考勤组')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-select style="width: 200px" show-search v-model="input.attGroupId" :placeholder="l('考勤组')">
						<a-select-option v-for="item in attendanceGroup" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpattdailyServiceProxy,
	EmpattmonthlyAnalysisDtos,
	EmpattmonthlyServiceProxy,
	EnterpriseAttendCycleServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';
import { abpService } from '../../../../shared/abp';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';
import _ from 'lodash';

export default {
	name: 'analysis',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			locale,
			UserList: [],
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			attendanceGroup: [], //考勤组
			attGroupId: undefined,
			input: new EmpattmonthlyAnalysisDtos(),
			attDate: undefined,
			attenanceDateApi: undefined,
			startTime: undefined, //开始时间
			endTime: undefined, //结束时间
		};
	},
	created() {
		this.fullData();
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
		// 考勤周期
		this.attenanceDateApi = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		if (this.attDate) {
			this.handChang(moment(this.attDate));
		}
		this.initCompany();
		if (abpService.abp.userInfo.companyId) {
			this.input.companyId = abpService.abp.userInfo.companyId;
		}
		this.attendanceGroup = await this.getAttGroup();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		handChang(e) {
			this.attDate = e;
			let date = moment(e);
			this.input.year = date.year();
			this.input.month = date.month() + 1;
			this.attenanceDateApi
				.getByTenant(this.input.year, this.input.month)
				.finally(() => {})
				.then((res) => {
					this.startTime = res.startTime;
					this.endTime = res.endTime;
				});
		},
		getAttGroup() {
			return new Promise((resolve) => {
				this.groupListApi
					.getGroupListByManage()
					.finally()
					.then((res) => {
						resolve(res);
					});
			});
		},
		selectUser() {
			let ids = _.map(this.UserList, 'id');
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRowKeys: ids,
					_selectedRows: this.UserList,
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.UserList = [];
					res.forEach((item, index) => {
						this.UserList = [...this.UserList, item];
					});
					console.log(this.UserList);
				}
			});
		},
		// 公司选择
		handleCompanyChange(value) {
			this.comPanyId = value;
			// this.getData();
			if (!value) {
				this.departmentId = undefined;
			}
			this.getDepTreeData();
		},

		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		save() {
			if (!this.input.year && !this.input.month) {
				return abp.message.warn('请选择出勤日期');
			}
			this.input.userIds = _.map(this.UserList, 'id').join(',');
			this.loading = true;
			this._empattmonthlyServiceProxy
				.analysis(this.input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.CheckInUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
</style>
